import React from 'react'
import {Link} from "react-router-dom"
import {ReactComponent as PenyesaLogo} from "../Images/accessoriesLogo.svg"
import {ReactComponent as PaypalLogo} from "../Images/PaypalLogo.svg"
import {ReactComponent as PayfastLogo} from "../Images/PayfastLogo.svg"
import {ReactComponent as VisaLogo} from "../Images/VisaLogo.svg"
import {ReactComponent as MastercardLogo} from "../Images/MastercardLogo.svg"
import WomanImage from "../Images/WomanImageCenterBig.png"
import WomanImageLeft from "../Images/WomanImageLeftBig.png"
import WomanImageRight from "../Images/WomanImageRightBig.png"
import Flag from "../Images/zimFlag.png"
import "../Styles/TopView.scss"

import Info from "./Info"

const TopView = () => {
    return (
        <div className="topview">
           
            <div className="toplogo-container">
                <PenyesaLogo className="toplogo-logo"/>
            </div>
            <div className="topview-gridContainer">
                <div className="topview-flank topViewHide">
                <img src={WomanImageLeft} alt="Penyesa Accessories " className="top-image"/>
                    <div className="payment-container topViewHide">
                        <PayfastLogo className="payment-icon"/>
                        <div className="payment-brands">
                            <MastercardLogo className="payment-brands--logo"/>
                            <VisaLogo className="payment-brands--logo"/>
                        </div>
                    </div>
                    {/* <div className="borderBottom"></div> */}
                </div>
                <div className="topview-middle">
                   
                    <img src={WomanImage} alt="Penyesa Accessories " className="top-image"/>
                    <div className ="top-text">
                       <h2 className="topview-phrase">You deserve to Shine!</h2>
                        
                        
                    </div>
                   
                    <Link to="/category/Tops">
                    <button className="btn-main">Shop now</button>
                    </Link>

                </div>
                <div className="topview-flank topViewHide">
                <img src={WomanImageRight} alt="Penyesa Accessories " className="top-image"/>
                    <div className="payment-container">
                        <PaypalLogo className="payment-icon"/>
                        <PayfastLogo className="payment-icon payment-hide-show"/>
                        <div className="payment-brands">
                            <MastercardLogo className="payment-brands--logo"/>
                            <VisaLogo className="payment-brands--logo"/>
                        </div>
                    </div>
                </div>
            </div>
            
        </div>
    )
}

export default TopView
