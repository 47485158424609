import React, {createContext, useReducer, useEffect}from 'react'
import axios from "axios"

export const ProductsContext = createContext()
const initialState = {
    products :  [
        {
            id : "Temp-01",
            brand : "Temp",
            stock_Qty : 10,
            isBrowse : true,
        },
        {
            id : "Temp-02",
            brand : "Temp",
            stock_Qty : 10,
            isBrowse : true,
            category : "Temp"
        },
        {
            id : "Temp-03",
            brand : "Temp",
            stock_Qty : 10,
            isBrowse : false,
            category : "Temp"
        },
        {
            id : "Temp-04",
            brand : "Temp",
            stock_Qty : 10,
            isBrowse : false,
            category : "Temp"
        },
        {
            id : "Temp-05",
            brand : "Temp",
            stock_Qty : 10,
            isBrowse : false,
            category : "Temp"
        },
        
        {
            id : "Temp-06",
            brand : "Temp",
            stock_Qty : 10,
            isBrowse : false,
            category : "Temp"
        },
        {
            id : "Temp-07",
            brand : "Temp",
            stock_Qty : 10,
            isBrowse : false,
            category : "Temp"
        },
        {
            id : "Temp-08",
            brand : "Temp",
            stock_Qty : 10,
            isBrowse : false,
            category : "Temp"
        },
        {
            id : "Temp-09",
            brand : "Temp",
            stock_Qty : 10,
            isBrowse : false,
            category : "Temp"
        },
        
        
    ],
    isLoading : true,
}



const reducer = (state, action) => {
    switch(action.type){
        case "Success" : 
            let products = [...action.payload] 
            products = products.filter(product => product.brand !== "Temp")
            
            const outOfStock = products.filter(product => product.stock_Qty <= 0)
            const inStock = products.filter(product => product.stock_Qty > 0)
            products = [...inStock,...outOfStock] 
            return {
                isLoading : false,
                products : products
            }
        case "Error" : {
            console.log(action.Err)
            alert("There was an Error Loading Products Please Refresh Or Contact Info@penyesa.co.za")
            return{
                ...state,
                isLoading : false
            }
        }
        case "AddCartLabel" : 
            const updatedAddChipProductData = state.products.map(product => (
                product.id === action.productID ? {...product, isInCart : true} : product
            ))
            return{
                ...state, 
                products : [...updatedAddChipProductData]
            }
        case "RemoveCartLabel" : 
            const updatedRemoveChipProductData = state.products.map(product => (
                product.id === action.productID ? {...product, isInCart : false} : product
            ))
        
            return{
                ...state, 
                products : [...updatedRemoveChipProductData]
            }
        case "AddStockQty" : 
            const updatedAddStockQty = state.products.map(product => (
                product.id === action.productID ? {...product , display_Stock : product.display_Stock + 1 } : product
            ))
            return {
                ...state,
                products : [...updatedAddStockQty]
            }
        case "RemoveStockQty" : 
               const updatedRemoveStockQty = state.products.map(product => (
                   product.id === action.productID ? {...product, display_Stock : product.display_Stock - 1} : product
               ))
        return {
                ...state,
                products : [...updatedRemoveStockQty]
            }
        case "LOAD" : 
        return {
            ...state,
            isLoading : action.loadState
        }
        default :
            return state
    }
}


export function ProductsContextProvider(props) {
    const [productDataState, productsDispatch] = useReducer(reducer, initialState)
        const getProducts = async () => {
        let products = [...productDataState.products]
        try {
            const response = axios.get(`https://rartx1msad.execute-api.eu-west-1.amazonaws.com/Production/products/ProductsAccess`)
            const results = (await response).data;
            results.map(result => products.push({
                id : result.id,
                brand : result.brand,
                title : result.title,
                size : result.size,
                price : result.price,
                url : `https://penyes-aaccessories.s3.af-south-1.amazonaws.com/${result.url}.jpg`,
                isBrowse : result.isBrowse,
                category : result.category,
                stock_Qty : result.stock_Qty, 
                display_Stock : result.stock_Qty
            }))

            productsDispatch({type : "Success", payload : products})


        } catch (error) {
            productsDispatch({type : "Error", Err : error})
        }
    }
    useEffect(() =>{
        getProducts();
    }, [])
    return (
        <ProductsContext.Provider value={{...productDataState, productsDispatch}}>
            {props.children}
        </ProductsContext.Provider>
    )
}


