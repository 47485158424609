import React, {createContext, useReducer} from 'react'

export const ControlContext = createContext()
const initialState = {
    cartOpenState : false,
    promotions  : true,
}

const reducer = (state, action) => {
    switch(action.type){
        case "CART" : 
            return {...state, cartOpenState : !state.cartOpenState}
        case "PROMO" : 
            return {
                ...state, promotions : !state.promotions
            }
        default :
        return state
    }

}
const ControlContextProvider = (props) => {
    const [controlState, controlDispatch] = useReducer(reducer,initialState)
    return (
        <ControlContext.Provider value={{...controlState, controlDispatch}}>
            {props.children}
        </ControlContext.Provider>
    )
}

export default ControlContextProvider
