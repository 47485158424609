import React, {createContext, useReducer} from 'react'
import createUID from 'create-unique-id';


export const CartContext = createContext();

//Define Initial State
const initialState = {
        cartTotal : 0,
        cartProducts : [],
        cartID : ""
        
}

//Define Reducer Function 
const reducer = (state, action) => {
    switch(action.type){
        case "AddProduct" :
            
            let tempCartArray = [...state.cartProducts]
            let cartTotal = state.cartTotal;
            const foundProductIndex = tempCartArray.findIndex(item => item.productID === action.itemID);
            if(foundProductIndex < 0){
                tempCartArray =[...tempCartArray, {
                    productID : action.itemID,
                    productBrand : action.itemBrand,
                    productTitle : action.itemTitle,
                    productPrice : action.itemPrice,
                    productImage : action.itemURL,
                    productDescription : action.itemDescription,
                    productSize : action.itemSize,
                    productQty : action.itemQty,
                    stock_Qty : action.stock_Qty,
                    display_Stock : action.display_Stock - 1
                }]
                cartTotal = cartTotal + 1;
            }else {
                
                tempCartArray[foundProductIndex].productQty += 1;
                tempCartArray[foundProductIndex].display_Stock -= 1;

            }
            
           return {
                ...state,
               cartTotal : cartTotal,
               cartProducts : tempCartArray
           }
        case "Subtract" :
            let tempCartArrayRemove = [...state.cartProducts]
            const foundProductIndexRemove = tempCartArrayRemove.findIndex(item => item.productID === action.itemID);
            if(foundProductIndexRemove >= 0){
                tempCartArrayRemove[foundProductIndexRemove].productQty -= 1;
                tempCartArrayRemove[foundProductIndexRemove].display_Stock += 1;
            }
            
            return {
                ...state,
                cartProducts : tempCartArrayRemove
            }
        case "RemoveProduct" :
            if(state.cartTotal > 0){
                return {
                    cartTotal : state.cartTotal - 1,
                    cartProducts : state.cartProducts.filter(item => item.productID !== action.itemID)
                }
            }else {
                return state
            }
        case "cartID" : 
        return {
            ...state,
            cartID : `${createUID(7)}`.toUpperCase()
        }
        case "Shipping" : 
            return {
                ...state,
                shippingCharges : action.shippingCharges
            }
        default :
            return state
    }
}

export function CartContextProvider(props) {
    const [cartState, cartDispatch] = useReducer(reducer,initialState)
    return (
        <CartContext.Provider value={{...cartState, cartDispatch}}>
            {props.children}
        </CartContext.Provider>
    )
}


