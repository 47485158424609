import React, {useState,useContext} from 'react'
import {ReactComponent as CartIcon} from '../Images/CartIcon.svg'
import {ReactComponent as InfoIcon }  from "../Images/MoreInfo.svg"
import {CartContext} from "../../Context/Cart.Context"
import { ProductsContext} from "../../Context/Products.Context"
import {ControlContext} from "../../Context/Control.Context"
import QuickViewModal from "./QuickViewModal"


import "../Styles/Card.scss"

const Card = ({product}) => {
    const {id,title,brand,price,size,url,isInCart,stock_Qty,display_Stock} = product
    const {cartProducts,cartDispatch} = useContext(CartContext)
    const {cartOpenState, controlDispatch} = useContext(ControlContext)
    const {isLoading, productsDispatch} = useContext(ProductsContext)
    const [isView, setIsView] = useState(false)
    const [isModal, setIsModal] = useState(false)
    const [sizeState,setSizeState] = useState("N");

    const currentProduct= cartProducts.find(item => {
        return item.productID === id
    })
    const quantity = currentProduct ? currentProduct.productQty : 0;

    //Add Quantities to Cart
    const handleRemoveQuantity = (id, qty) => {
        if(qty > 1){
          
            cartDispatch({type : "Subtract", itemID : id})
            
        }else{
            cartDispatch({type: "RemoveProduct", itemID:id})
            //Remove Cart Label on Product
            productsDispatch({type:"RemoveCartLabel", productID : id})
            
        }
        //Add Stock Total
        productsDispatch({type : "AddStockQty", productID: id})
    }
    //Add to Cart
    const handleCheckSize = (sizes) => {
        if(sizes !== "N" && sizes !== "" ){
            handleAddToCart();
        }else{
            handleModalView(true);
        }
    }
    const handleAddToCart = () => {
        cartDispatch({
            type : "AddProduct",
            itemID : id,
            itemBrand : brand,
            itemTitle : title,
            itemPrice : price,
            itemSize : `${size} Size:${sizeState}`,
            itemURL : url,
            itemQty : 1,
            stock_Qty,display_Stock

        })
        //Add Cart Label on Product
        productsDispatch({type:"AddCartLabel", productID : id})
        //Subtract Stock Total
        productsDispatch({type : "RemoveStockQty", productID: id})
    }
    const handleControlCart = () => {
        controlDispatch({type: "CART"})
    }
    const handleQuickViewOff = () =>{
        setIsView(false);
    }
    const handleModalView = (value) => {
        setIsModal(value);
    }
    return (
        

        <>
            <div className="card-container">
                <div className="card">
                        {
                            stock_Qty <= 0 && <div className="hover-card hover-card--stock">
                                <div onClick={() => handleModalView(true)} className="hover-info">
                                        Out of Stock
                                    </div>
                            </div>
                        }
                        {
                            isInCart && <div onClick={handleControlCart} className="card-cartLabel">
                                In cart
                            </div>

                        }
                        {
                            isInCart && <div onClick={handleControlCart} className="card-cartLabel card-cartLabel-quantity">
                                {quantity}
                            </div>
                        }
                        {
                            !isLoading &&
                            <>
                                <div className="card-image">
                                    <img className="card-image--png"src={url} alt={title}/>
                                </div>
                                <div className="hover-price">
                                    <span className="hover-price-label">R</span>{`${price}`}
                                </div>
                                <div className="card-sizes">
                                    <div onClick={() => setSizeState("S")} className={`card-sizes--select ${sizeState === "S" ? "card-sizes--select-active" : ""}`}>
                                        <span className="card-sizes--text">
                                            S
                                        </span>
                                    </div>
                                    <div onClick={() => setSizeState("M")} className={`card-sizes--select ${sizeState === "M" ? "card-sizes--select-active" : ""}`}>
                                        <span className="card-sizes--text">
                                            M
                                        </span>
                                    </div>
                                    <div onClick={() => setSizeState("L")} className={`card-sizes--select ${sizeState === "L" ? "card-sizes--select-active" : ""}`}>
                                        <span className="card-sizes--text">
                                            L
                                        </span>
                                    </div>
                                </div>
                            </>
                        }
                        
                </div>
                {
                    !isLoading && (
                        <div className="card-info">
                            <div className="card-text">
                                <h1 className="card-header">{brand}</h1>
                                <h4 className="card-title">{title}</h4>
                                <h5 className="card-size">{size}</h5>
                            </div>
                            <div className="card-control">
                            <div className="card-cart">
                                    {
                                        stock_Qty > 0 && (
                                            <button onClick={() => handleModalView(true)} className="cart-btn" disabled={display_Stock <= 0 ? true : false}>
                                                <span className="cart-add">Info</span>
                                                <InfoIcon className="cart-icon"/>
                                            </button>
                                        )
                                    }
                                </div>
                                <div className="card-cart">
                                    {
                                        stock_Qty > 0 && (
                                            <button onClick={() => handleCheckSize(sizeState)} className="cart-btn" disabled={display_Stock <= 0 ? true : false}>
                                                <span className="cart-add">Add</span>
                                                <CartIcon className="cart-icon"/>
                                            </button>
                                        )
                                    }
                                </div>
                            </div>
                        </div>
                    )
                }
            </div>    
            {
                isModal && <QuickViewModal 
                            showModal={handleModalView}
                            sizeState={sizeState}
                            handleSize={setSizeState}
                            addCart={handleAddToCart}
                            subtractCart={handleRemoveQuantity} 
                            product={{...product, quantity}}
                            />
            }
        </>
    )
}

export default Card
