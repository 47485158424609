import React, {useState,useEffect,useContext} from 'react'
import {useHistory} from "react-router-dom"
import "../Styles/ShipmentMethod.scss"
import FormError from "./errorMessage"
import axios from "axios"
import {ReactComponent as CollectionLogo} from "../Images/pickup.svg"



const ShipmentMethod = ({handleSetShipState,handleSubmitShipMethod,selectedShipMethod,shipMethodError,handleSelectedShipMethod}) => {
    
let history = useHistory();
const [loadState, setLoadState] = useState(false);


const renderLoader = (id) => <div className="loader-shipping"></div>
const handleInputchange = (e) => {
    
    const {value} = e.target;
    handleSelectedShipMethod(value);

}

    return (
        <div className="shipmentMethod">
            <div className="shipmentMethod-frame">
                {
                   shipMethodError === true && <FormError error="Please select a shipping method"/>
                }
                <div className="shipmentMethod-item">
                    <input type="radio" value="Fastway" name="shipmentMethod" onChange={handleInputchange} checked={selectedShipMethod === "Fastway" ? true : false}/>
                    <div className="shipmentMethod-icon"></div>
                    <span className="shipmentMethod-title">Courier door delivery</span>
                    <span className = "shipmentMethod-text">Within 2-3 days</span>
                    <span className="shipmentMethod-price">R100</span>
                </div>
                <div className="shipmentMethod-item">
                    <input type="radio" value="Collection" name="shipmentMethod" onChange={handleInputchange} checked={selectedShipMethod === "Collection" ? true : false}/>
                    <CollectionLogo/>
                    <span className="shipmentMethod-title">In store pickup</span>
                    <span className = "shipmentMethod-text">Scheduled</span>
                    <span className="shipmentMethod-price">R0.00</span>
                </div>
                {
                    selectedShipMethod === "Collection" && <p className="shipment-collection">Please contact us at info@penyesa.co.za or +27 71 887 9061 to arrange for collection. </p>
                }
            </div>
             <div className="button-group">
                <button className="form-back" name="back" onClick={() => handleSetShipState(0)}><span className="form-back-text">&#8592;</span>back</button>
                <button className="form-next" name="next" onClick={() => handleSubmitShipMethod()}>next</button>     
            </div>
        </div>
    )
}

export default ShipmentMethod
