import React, {useEffect, useState,useContext,useRef}from 'react'
import BackDrop from "../../Shared/Components/BackDrop"
import {useHistory} from "react-router-dom"
import {CartContext} from "../../Context/Cart.Context"
import axios from 'axios';


const PayWithPaypal = ({formState,cartProducts,shippingCharges}) => {
    const {cartID} = useContext(CartContext);
    const [payingState, setPayingState] = useState(false)
    const {shipName,shipSurname,shipMobile,shipEmail,shipAddress,shipCity,shipCountry} = formState;
    const {billName,billSurname,billMobile,billEmail,billAddress,deliveryInfo,paymentMethod} = formState;
    const [error, setError] = useState(null);
    const history = useHistory()
    const payPalRef = useRef();
    
    let Total = 0;
    let vatTotal = 0;
    let subTotal = 0;
    let shippingCost = 0;

    const handleRedirect = (Order_ID) => {
        setPayingState(false);
        history.push(`/success/${Order_ID}`)
    }
    
  
   const convertToSubCurrency = (perc,baseVal,rateValue) => {
       const baseAmount =  (baseVal * perc)
       return (baseAmount * rateValue).toString().match(/^-?\d+(?:\.\d{0,2})?/)[0];
   }

   const handleCreateOrder = async () =>{
    try{
        const response = await axios({
            method : "post",
            url : "https://rartx1msad.execute-api.eu-west-1.amazonaws.com/PenyesaAccessories/pendingorders",
            data : JSON.stringify({ 
                Order_ID : `${cartID}`,
                    billName : `${billName} ${billSurname}`,
                    billEmail,
                    billMobile,
                    billAddress,
                    paymentMethod,
                    deliveryIntsruction :deliveryInfo,
                    shipName : `${shipName} ${shipSurname}`,
                    shipAddress : `${shipAddress}, ${shipCity} ${shipCountry}`,
                    shipMobile,
                    shipEmail,
                    shippingCharges,
                    products : cartProducts
            })             
        })
        return response;
    }catch(err){
        console.log(err.toJSON());
        localStorage.setItem(`${cartID}`, JSON.stringify(
            {
                billName : `${billName} ${billSurname}`,
                billEmail,
                billMobile,
                billAddress,
                paymentMethod,
                deliveryInfo,
                shipName : `${shipName} ${shipSurname}`,
                shipAddress : `${shipAddress}, ${shipCity} ${shipCountry}`,
                shipMobile,
                vatTotal,
                subTotal,
                shippingCharges,
                products : cartProducts.map(product => {
                    return {
                        ...product
                    }
                })
            }
        ))
    }
   
}
useEffect(() => {
    let products = [];
    const getCurrency = async () =>{
        const results = await axios.get("https://api.exchangeratesapi.io/latest?base=ZAR")
        const rates = results.data.rates
        shippingCost = convertToSubCurrency(1, shippingCharges, rates["USD"])
        products = await cartProducts.map((item) => {
            return {
                name : `${item.productBrand} ${item.productTitle} ${item.productSize}`,
                unit_amount : {
                    currency_code : "USD",
                    value : convertToSubCurrency(0.85,item.productPrice,rates["USD"]),

                },
                tax : {
                    currency_code : "USD",
                    value : convertToSubCurrency(0.15,item.productPrice,rates["USD"]),
                },
                quantity : item.productQty,
            }
        })
        
        vatTotal = products.reduce((acc,current) => acc + (current.tax.value * current.quantity),0).toFixed(2);
        subTotal = products.reduce((acc,current) => acc + (current.unit_amount.value * current.quantity),0).toFixed(2);
        Total = (parseFloat(vatTotal) + parseFloat(subTotal) +parseFloat(shippingCost)).toFixed(2);
       
    }
    getCurrency();
    window.paypal
        .Buttons({
            createOrder : async (data, actions) => {
                try{
                    const resp = await handleCreateOrder()
                }catch(err){
                    console.log(err);
                }
                return actions.order.create({
                    intent : 'CAPTURE',
                    purchase_units : [{
                        amount : {
                            currency_code : 'USD',
                            value : Total,
                            breakdown : {
                                item_total : {
                                    currency_code : 'USD',
                                    value : subTotal,
                                },
                                shipping : {
                                    currency_code : 'USD',
                                    value : shippingCost
                                },
                                tax_total : {
                                    currency_code : 'USD',
                                    value : vatTotal,
                                }
                            }
                            
                        },
                        payee : {
                            email_address : "shorai@penyesa.co.za",
                            merchant_ID : "NHY7D537MQNNN"
                        },
                        description : "Penyesa Pantry groceries",
                        invoice_id : `${cartID}`,
                        items : products,
                shipping : {
                            name : {
                                full_name : `${shipName}  ${shipSurname}`
                            },
                            address : {
                                address_line_1 : shipAddress,
                                admin_area_2 : shipCity,
                                country_code : "ZA"
                            },
                            option :{
                                id : `${cartID}`,
                                label : "Standard Shipping",
                                type : "SHIPPING",
                                amount : {
                                    currency_code : "USD",
                                    value : shippingCost
                                },
                                
                            }
                        }
                    }]
                })
            },
            onApprove : async (data,actions) =>  {
                setPayingState(true);
                const order = await actions.order.capture()        
               order.status === "COMPLETED" && handleRedirect(order.purchase_units[0].invoice_id)
            },
            onCancel : (data,actions) => {
                {/**Handle on Cancel */}
                console.log("You are a bummer!");
            },
            onError : err => {
                setError(err);
                console.log(err.toJSON())
            }
        }).render(payPalRef.current)

    
},[cartProducts])

    return (
        <div>
          <div ref={payPalRef} />
          <BackDrop openState={payingState}/>
        </div>
    )
}

export default PayWithPaypal
