import React from 'react'
import {ReactComponent as PinkHeart} from "./Images/pinkHeart.svg"
import {ReactComponent as RedHeart} from "./Images/redHeart.svg"
import {ReactComponent as PeachHeart} from "./Images/peachHeart.svg"

const Valentines = ({promoControl}) => {
    const OutHearts = ["peach","red","red","pink","red","pink","peach","peach","red","red"]
    
    const DisplayHeart = () =>{
        return OutHearts.map((heart,index) => {
            switch(heart){
                case "pink" :
                    return <PinkHeart key={`OutsideHeartPink-${index}`}
                            className={`heart-outside heart-number--${index}`}/>
                case "red" : 
                    return <RedHeart key={`OutsideHeartRed-${index}`}
                            className={`heart-outside heart-number--${index}`}/>
                case "peach" :
                    return <PeachHeart key={`OutsideHeartPeach-${index}`}
                            className={`heart-outside heart-number--${index}`}/>
                default :
                    return null 
            }
        })
    }
    const handleBtnClick = () =>{
        promoControl("category/Tops")
    }
    return (
        <div className="valentine">
            <i className="far fa-window-close" onClick={handleBtnClick}></i>
            
            <div className="valentine-card">
                {
                        DisplayHeart()
                } 
                <h3 className="valentine-heading">Special offer</h3>
                <h1 className="valentine-title">
                    <span className="valentine-word">Valentine's</span>
                    <span className="valentine-word">Day</span>
                </h1>
                <h3 className="valentine-sale"><span className="valentine-off">30%</span> Off sale</h3>
                <div className="valentine-btn" onClick={handleBtnClick}>
                    shop now
                </div>
            </div>      
            
        </div>
    )
}

export default Valentines
