import React, {useContext,useState} from 'react'
import {ControlContext} from "../../Context/Control.Context"

import "../Styles/QuickViewModal.scss"


const QuickViewModal = ({showModal,product, addCart, subtractCart,handleSize,sizeState}) => {
    const {controlDispatch} = useContext(ControlContext)
    const {id,title,brand,price,size,url,isInCart,description,quantity,stock_Qty,display_Stock} = product
    const [isSizeSelected,setIsSizeSelected] = useState(true);
    const handleOpenCart = () => {
        controlDispatch({type:"CART"})
        showModal(false)
    }
    const handleSetSize = (name) => {

        handleSize(name);
        setIsSizeSelected(true);
    }
    const handleCheckSize = () => {
        if(sizeState === "S" || sizeState === "M" || sizeState === "L"){
            addCart();
        }else{
            setIsSizeSelected(false);
        }
    }

    return (
        <div className="modal">
            <div className="modal-container">
                <div className="modal-image">
                   <div className="modal-image-frame">
                   {
                       isInCart && (
                        <div className="modal-label" onClick={handleOpenCart} >
                            In Cart
                        </div>
                       )
                   }
                    <img src={url} alt="Product"/> 
                    
                   </div>
                </div>
                <div className="modal-info">
                    <div className="modal-header">
                        <div className="modal-heading">
                            {`${brand} ${title}`}                     
                        </div>
                        <i className="far fa-window-close" onClick={() => showModal(false)} ></i>
                    </div>
                    <div className="modal-price">
                        {`R${price}`}
                    </div>
                    <div className="modal-text">
                        <div className={stock_Qty > 0 ? "modal-stock" : "modal-stock--out"}>
                            {stock_Qty > 0 && display_Stock > 0 ? `${display_Stock} In Stock` : "Out of stock"}
                        </div>
                        {
                            description ?(
                            <p className="prod-desc">
                                {description}
                            </p>
                            ) : (
                                <div className="prod-size">
                                    {`${brand} ${title} ${size}`}
                                </div>
                            )
                        }
                        <h6 className={`modal-sizes--heading ${!isSizeSelected && "modal-stock--out"}`}> 
                            {
                                !isSizeSelected && "Please "
                            }
                            Select Size
                        </h6>
                        <div className="modal-sizes">
                           
                            <div name="S" onClick={() => handleSetSize("S")} className={`modal-sizes--select ${sizeState === "S" ? "modal-sizes--select-active" : ""}`}>
                                <span className="modal-sizes--text">S</span>
                            </div>
                            <div name="M" onClick={() => handleSetSize("M")} className={`modal-sizes--select ${sizeState === "M" ? "modal-sizes--select-active" : ""}`}>
                                <span className="modal-sizes--text">M</span>
                            </div>
                            <div name="L" onClick={() => handleSetSize("L")} className={`modal-sizes--select ${sizeState === "L" ? "modal-sizes--select-active" : ""}`}>
                                <span className="modal-sizes--text">L</span>
                        </div>
                    </div>
                    </div>
                    {   display_Stock > 0 &&
                        <div className="modal-control" >
                            {
                                isInCart ? (
                                    <>
                                        <i className="far fa-minus-square" onClick={()=> subtractCart(id,quantity)}></i>
                                        <button className="modal-btn" onClick={handleOpenCart} >
                                            {quantity}
                                            <span>|</span>In Cart
                                        </button>
                                        <i className="fas fa-plus-square" onClick={() => addCart()}></i>
                                    </>
                                ) :
                                (
                                    <>
                                        <button className="modal-btn" onClick={handleCheckSize} >
                                        <i className="fas fa-shopping-basket"></i>
                                            <span>|</span>Add to Cart
                                        </button>
                                    </>
                                )
                            }
                        </div>
                    }
                </div>
            </div>
        </div>
    )
}

export default QuickViewModal
