import React, {useContext,useEffect} from 'react'
import {useHistory} from "react-router-dom"
import {ControlContext} from "../Context/Control.Context"
import {PromotionsContext} from "../Context/Promotions.Context"
import Valentines from "./Valentines"

const Promotions = () => {

    const history = useHistory();
    const {promotions,controlDispatch } = useContext(ControlContext)
    const {promoType} = useContext(PromotionsContext)
    
    const handlePromoStatus = (page) =>
    {
        controlDispatch({type: "PROMO"})
        history.push(`/${page}`)
        window.scrollTo({
            top : 195,
            left : 0,
            behavior: "smooth"
        });
    }
    const CurrentPromo = () => {
        switch(promoType){
            case "Valentine" :
                return <Valentines promoControl={handlePromoStatus}/>;
            default : 
                return null
        }
    }
    const DisplayPromo = () => {
        if(promotions){
            return CurrentPromo();
        }else {
            return null;
        }
    }
    return (
        DisplayPromo()
        
    )
}

export default Promotions
