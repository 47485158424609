import React, {useState} from 'react'
import { useLocation } from 'react-router-dom'
import "../Styles/BreadCrumb.scss"

const urls = [ "/checkout/shipping","/checkout/payment","/checkout/review"]
function Breadcrumb() {
    let location = useLocation();
    const stringName = location.pathname
    const countState = urls.findIndex((indexItem => {return indexItem === stringName} ))
    
    
    return (
        <div className="breadcrumb">
            {/**Shipping */}
            <div className="breadcrumb-group">
                <div className={`breadcrumb-circle ${countState === 0 && "breadcrumb-active"} ${countState > 0 && "breadcrumb-checked"}`}>
                    {countState > 0 ? <i className="fa fa-check" aria-hidden="true"></i> :  "1"}
                </div>
                <p className="breadcrumb-text">Shipping</p>
            </div>
            <div className="breadcrumb-rule--container">
                    <hr className="breadcrumb-rule"/>
            </div>
            
            {/**Billing */}
            <div className="breadcrumb-group">
                <div className={`breadcrumb-circle ${countState === 1 && "breadcrumb-active"} ${countState > 1 && "breadcrumb-checked"}`}>
                    {countState > 1 ? <i className="fa fa-check" aria-hidden="true"></i> : "2" }
                </div>
                <p className="breadcrumb-text">Payment</p>
                
            </div>
            <div className="breadcrumb-rule--container">
                    <hr className="breadcrumb-rule"/>
            </div>
            {/**Review*/}
            <div className="breadcrumb-group">
            <div className={`breadcrumb-circle ${countState === 2 && "breadcrumb-active"} ${countState > 2 && "breadcrumb-checked"}`}>
                {countState > 2 ? <i className="fa fa-check" aria-hidden="true"></i> : "3"}
            </div>
            <p className="breadcrumb-text">Review</p>
            </div>
        </div>
    )
}

export default Breadcrumb
