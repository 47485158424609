import React, {useContext} from 'react'
import {ReactComponent as Facebook} from "../Images/Facebook-dark.svg"
import {ReactComponent as Instagram} from "../Images/Instagram-dark.svg"
import {ReactComponent as Pinterest} from "../Images/Pinterest-dark.svg"
import {ReactComponent as Mobile} from "../Images/Mobile.svg"
import {ReactComponent as Email} from "../Images/Email.svg"
import {ReactComponent as CartIcon} from "../Images/Cart.svg"
import {NavLink} from "react-router-dom"
import {ControlContext} from "../../Context/Control.Context"
import "../Styles/Navbar.scss"



const Navbar = () => {
    const {controlDispatch} = useContext(ControlContext)
    const handleCartControl = () => {
        controlDispatch({type : "CART"});
    }
    return (
        <div className="nav">
            <div className="nav-contact">
                <div className="nav-mobile">
                    <Mobile className="nav-icon"/>
                    <span className='nav-text'>
                    <a href="tel:+27658087492">
                        +27 65 808 7492
                    </a>
                    </span>
                </div>
                <div className="nav-email">
                    <Email className="nav-icon"/>
                    <span className='nav-text'>
                    <a href="mailto:info@penyesa.co.za?bcc=developer20@penyesa.co.za&subject=Enquiry&body=Greetings%20Penyesa%2C%0D%0A%0D%0AI%20would%20like%20to%20make%20an%20enquiry%20regarding%20%3A%0D%0A%0D%0A%0D%0A%0D%0A">
                        info@penyesa.co.za
                    </a>
                    </span>
                </div>
            </div>
            <div className="nav-social">
                <a href="https://www.facebook.com/PenyesaSA/"><Facebook className="nav-icon" /></a>
                <a href="https://www.instagram.com/PenyesaSA/"><Instagram className="nav-icon"/></a>
                <a href="http://www.pinterest.com/PenyesaSA"><Pinterest className="nav-icon"/></a>
                
                
            </div>
            <div className="nav-navigation">
                <NavLink className="nav-link" to="/">
                    Home
                </NavLink>
                <NavLink className="nav-link" to="/category/Tops">
                    Shop
                </NavLink>
                {/* <NavLink className="nav-link" to="/">
                    Home
                </NavLink> */}
                <div className="nav-icon">
                    <CartIcon onClick={handleCartControl} className="nav-icon--cart"/>
                    <div className="nav-icon--label"></div>
                </div>

                
            </div>
        </div>
    )
}

export default Navbar
