import React, {useContext, useState, useEffect,lazy,Suspense} from 'react'
import "../Styles/Category.scss"
import "../../Home/Styles/Category.scss"
import {ReactComponent as CategoryLogo} from "../Images/PenyesaLogoWhite.svg"
import {ReactComponent as TopsIcon} from "../Images/TopsIcon.svg"
import {ReactComponent as DressIcon} from "../Images/DressIcon.svg"
import {ReactComponent as BottomIcon} from "../Images/pantsIcon.svg"
import {ReactComponent as WinterIcon} from "../Images/winterIcon.svg"
import {ReactComponent as AccessoriesIcon} from "../Images/AccessoriesIcon.svg"
import { Link, useParams} from "react-router-dom"
import {ProductsContext} from "../../Context/Products.Context"
import Footer from '../../Shared/Components/Footer'
import CartButton from '../../Shared/Components/CartButton'
import Pagination from "./Pagination"
import {Helmet} from "react-helmet"
import "../Styles/loader.scss"




const MetaData = {
    Tops : {
        title : "Penyesa Tops | African themed summer tops",
        description : "Penyesa Tops are African themed, designed to make you shine whilst keeping you comfortable. Versatile Petal Tops. Off Shoulder Afroprint, latest African fashion trends"
    },
    Dresses :{
        title : "Penyesa Dresses | Dress to shine with Penyesa Dresses",
        description : "Penyesa dresses elegant Afroprint. Summer dresses that are comfortable and make you feel good. Shop online for summer dresses."
    },
    Accessories :{
        title : "Penyesa Accessories | Shop for unique Penyesa fashion pieces",
        description : "Wide range of handmade African accessories. Local and Afro themed masks, head bands and jewellery. Shop online fast fashion delivery."
    },
    Bottoms :{
        title : "Penyesa Accessories | Shop for unique Penyesa fashion pieces",
        description : "Wide range of stylish bottoms. Shop online fast fashion delivery."
    },
    Winterwear :{
        title : "Penyesa Dresses | Dress to shine with Penyesa Dresses",
        description : "Shine and keep warm with Penyesa.Shop online and stay warm during winter."
    },
}

//Dynamic load Card
const Card = lazy(() => import("../../Shared/Components/Card"))
const renderLoader = (id) => <div key={`loader-${id}`} className="loader"></div>


function Category() {
    const {title} = useParams();
    const {isLoading,products} = useContext(ProductsContext);

    //Set Up pagination
    const [items, setItems] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage, setItemsPerPage] = useState(16);


    const categoryProducts = products.filter(product => {
        return product.category === title || product.category === "Temp"
    })
       const categories = {
        tops :   (
            <Link onClick={() => setCurrentPage(1)} to="/category/Tops">
                <div className={`category-card cat-card ${title === "Tops" && "cat-active"}`} key="Tops-cat" name="Tops">
                    <h1 className="category-heading cat-heading ">
                        <TopsIcon/>
                        <div className="cat-text">Tops</div>
                    </h1>
                </div>
            </Link>
        ) ,
        dresses : (
            <Link onClick={() => setCurrentPage(1)} to="/category/Dresses">
                <div className={`category-card cat-card ${title === "Dresses" && "cat-active"}`} key="dresses-cat"name="Dresses">
                    <h1 className="category-heading cat-heading">
                        <DressIcon/>
                        <div className="cat-text">Dresses</div>
                    </h1>
                </div>
            </Link>
        ),
        accessories : (
            <Link onClick={() => setCurrentPage(1)} to="/category/Accessories">
                <div className={`category-card cat-card ${title === "Accessories" && "cat-active"}`} key="Accessories-cat" name="Accessories">
                    <h1 className="category-heading cat-heading">
                        <AccessoriesIcon/>
                        <div className="cat-text">Accessories</div>
                    </h1>
                </div>
            </Link>
        ),
        bottoms : (
            <Link onClick={() => setCurrentPage(1)} to="/category/Bottoms">
                <div className={`category-card cat-card ${title === "Bottoms" && "cat-active"}`} key="Bottoms-cat"name="Bottoms">
                    <h1 className="category-heading cat-heading">
                        <BottomIcon/>
                        <div className="cat-text">Bottoms</div>
                    </h1>
                </div>
            </Link>
        ),
        winterWear : (
            <Link onClick={() => setCurrentPage(1)} to="/category/Winterwear">
                <div className={`category-card cat-card ${title === "Winterwear" && "cat-active"}`} key="Winterwear-cat" name="Winterwear">
                    <h1 className="category-heading cat-heading">
                        <WinterIcon/>
                        <div className="cat-text">Winter Wear</div>
                    </h1>
                </div>
            </Link>
        ),
    }
    
    
    const indexOfLastPage = currentPage * itemsPerPage;
    const indexOfFirstPage = indexOfLastPage - itemsPerPage;
    const currentItems = categoryProducts.slice(indexOfFirstPage,indexOfLastPage);

    //Change Page 
    const handlePaginate = (pageNumber) => {
        setCurrentPage(pageNumber)
    }
    useEffect(() => {
        window.scrollTo({
            top : 30,
            left : 0,
            behavior: "smooth"
        });
    }, [currentPage])

   
    return (
        <>
            <div className="cat">
                <Helmet>
                    <title>{MetaData[title].title}</title>
                    <meta name="description" content={MetaData[title].description} />
                </Helmet>
                <div className="cat-display">
                    <CategoryLogo className="cat-logo"/>
                </div>
                <div className="category">
                    {
                        Object.keys(categories).map(key => categories[key])
                    }
                </div>
                <div className="services">
                    <div className="services-heading">
                        <hr className="services-rule"/>
                        <h1 className="services-heading--text">{title}</h1>
                        <hr className="services-rule"/>
                    </div>
                    <div className="shop">
                        {
                            currentItems.map(card => <Suspense fallback={renderLoader(card.id)}>
                                                        {
                                                            isLoading ? renderLoader(card.id) : <Card key={`cat-${card.id}`} product={{...card}}/>
                                                        }
                                                         
                                                    </Suspense>
                            )}
                    </div>
                    <Pagination itemsPerPage={itemsPerPage} currentPage={currentPage} totalItems={categoryProducts.length} paginate={handlePaginate}/>
                </div>
                
            
            </div>
            <CartButton/>
            <Footer/>
        </>
    )
}

export default Category
