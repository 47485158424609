import React, {useState, useContext}from 'react'
import Breadcrumb from "./Breadcrumb"
import OrderSummry from "./OrderSummary"
import Shipment from "./Shipment"
import Payment from "./Payment"
import Review from "./Review"
import { useLocation } from 'react-router-dom'
import {CartContext} from "../../Context/Cart.Context"
import "../Styles/Checkout.scss"
import Particles from "react-particles-js"

const initialFormState = {
    shipName : "",
    shipSurname : "",
    shipMobile : "",
    shipEmail : "",
    shipAddress : "",
    shipCity : "",
    shipCountry : "",
    deliveryInfo : "",
    paymentMethod : "",
    billName : "",
    billSurname : "",
    billMobile : "",
    billEmail : "",
    billAddress : "",
}
const initialStateErrorShip = {
    
    shipName : 'Field is required',
    shipSurname : "Field is required",
    shipMobile  : "Field is required",
    shipAddress : "Field is required",
    shipCity : "Field is required",
    shipCountry : "Field is required",
    shipEmail : "",
}
const initialStateErrorPay = {
    
    paymentMethod : "Field is required",
    billName : "Field is required",
    billSurname : "Field is required",
    billMobile : "Field is required",
    billEmail : "Field is required",
    billAddress : "Field is required"
}

function Checkout() {  
    let location = useLocation();
    const {cartProducts, shippingCharges} = useContext(CartContext)
    const [formState, setFormState] = useState(initialFormState)
    const [initShipError,setInitShipError] = useState(initialStateErrorShip);
    const [initPayError,setInitPayError] = useState(initialStateErrorPay);
    const [shipmentState, setShipmentState] = useState(0);

    const handleSetShipState = (value) => {
        setShipmentState(value);
    }
    const handleFormInput = (name, value) => {
        setFormState({
            ...formState, [name] : value
        })
    } 
    const handleSetInitialShipError = (val) => {
        setInitShipError(val)
    }
    const handleSetInitialPayError = (val) => {
        setInitPayError(val)
    }
    const currentForm = () => {
        switch (location.pathname) {
            case  "/checkout/shipping":
                return <Shipment formState={formState} inputChange={handleFormInput} shipmentState={shipmentState}
                    initialState={initShipError} setInitialState={handleSetInitialShipError} handleSetShipState={handleSetShipState} />
            case "/checkout/payment" :
                return <Payment formState={formState} inputChange={handleFormInput} initialState={initPayError} setInitialState={handleSetInitialPayError}/>
            case "/checkout/review" : 
                return <Review  formState={formState} cartProducts={cartProducts} shippingCharges={shippingCharges}/>
            default:
                break;
        }
    }            
    return (
        <div className="checkout">
            <div className="checkout-container">
                <Breadcrumb countState={location.pathname} />
                <div className="checkForm-container">
                    {
                        currentForm()
                    }
                </div>
                <OrderSummry cartProducts={cartProducts} shippingCharges={shippingCharges}/>
            </div>
            <Particles
                style={{
                    position: "absolute",
                    top: "0",
                    left : "0",
                    zIndex : "0",
                    height : "50%"

                }}
                params={{
                    
                    "particles": {
                        "number": {
                            "value": 640,
                            "density": {
                                "enable": true,
                                "value_area": 1500
                            }
                        },
                        "line_linked": {
                            "enable": true,
                            "opacity": 0.084,
                            "distance" : 80,
                        },
                        "move": {
                            "enable" : true,
                            "straight" : false,
                            "random" : true,
                            "out_mode": "out",
                            "speed": 0.8
                            
                        },
                        "size": {
                            "value": 3,
                            "random": true,
                            "anim" : {
                                "enable" : true,
                                "speed" : 10,
                                "size_min" : 0.3,
                                "anim_sync": false,
                            }
                        },
                        "opacity": {
                            value : "0.061",
                            "anim": {
                                "enable": true,
                                "speed": 1,
                                "opacity_min": 0.15
                            }
                        }
                    },
                    
                    "interactivity": {
                        "events": {
                            "onclick": {
                                "enable": true,
                                "mode": "push"
                            }
                        },
                        "modes": {
                            "push": {
                                "particles_nb": 2
                            }
                        }
                    },
                    "retina_detect": true
                }} />
        </div>
    )
}

export default Checkout
