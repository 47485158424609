import React, {useContext}  from 'react'
import TopView from './TopView'
import {ControlContext} from "../../Context/Control.Context"
import "../Styles/util.scss"
import Browse from './Browse'
import Footer from '../../Shared/Components/Footer'
import CartButton from '../../Shared/Components/CartButton'
import {Helmet} from "react-helmet"



const Home = (props) => {
    const {promotions, controlDispatch} = useContext(ControlContext)
    const handleOpenPromotions = () => {
        controlDispatch({type :"PROMO"});
    }
    return (
        <div className="home">
            <Helmet>
                <meta charSet="UTF-8"/>
                <title>Penyesa Accessories | African themed fashion</title>
                <meta name="description" content="Penyesa Accessories. Shop online for African themed fashion. Wide range of Afroprint Tops, dresses and fashion accessories.Afroprint masks, headbands and jewellery"/>
                <link rel="canonical" href="https://accessories.penyesa.co.za" />
            </Helmet>
            <TopView/>
            
            {/**promotions && <Promotions handleOpenPromotions={handleOpenPromotions}/> */}
            {/**<Category/>*/}
            <Browse/>
            <Footer/>
            <CartButton/>
            
        </div>
    )
}

export default Home;