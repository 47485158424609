import React, {useContext} from 'react';
import { Switch, Route } from 'react-router-dom';
import Home from "./Home/Components/Home"
import Category from "./Category/Components/Category"
import Navbar from "./Shared/Components/Navbar"
import SideBarCart from './Shared/Components/SideBarCart'
import Checkout from "./Checkout/Components/Checkout"
import ControlContextProvider from "./Context/Control.Context"
import {CartContextProvider} from "./Context/Cart.Context"
import {ProductsContextProvider} from "./Context/Products.Context"
import Success from './Shared/Components/Success';
import PromotionsContextProvider from './Context/Promotions.Context';
import Promotions from './Promotions/Promotions';
import "./Shared/Styles/Main.scss"



function App() {
  
  
  return (
    <>
      <PromotionsContextProvider>
      <ControlContextProvider>
      <ProductsContextProvider>
      <CartContextProvider>
          <Navbar/>
          {/* <QuickViewModal/> */}
          <SideBarCart/>
          <Promotions/>
          <Switch>
            <Route exact path="/" render={(routeProps) => <Home {...routeProps}/>}/>
            <Route exact path="/category/:title" render={(routeProps) => <Category {...routeProps}/> } />
            <Route exact path="/checkout/:page" render={(routeProps) => <Checkout {...routeProps}/>}/>
            <Route exact path="/success/:id" render={(routeProps) => <Success {...routeProps}/>}/>
          </Switch>
      </CartContextProvider>
      </ProductsContextProvider>
      </ControlContextProvider>
      </PromotionsContextProvider>
    </>
    
  );
}

export default App;
