import React from 'react'
import "../Styles/Review.scss"
import {ReactComponent as Payfast} from "../Images/PayfastLogoR.svg"
import {ReactComponent as Paypal} from "../Images/PaypalLogoR.svg"
import {useHistory} from "react-router-dom"
import PayWithPayFast from "./PayWithPayfast"
import PayWithPaypal from "./PayWithPaypal"

const Review = ({cartProducts,formState,shippingCharges}) => {
    const history = useHistory();
    const paymentMethod = formState.paymentMethod;
    const defaultNumber = (0).toFixed(2)
    const Total = cartProducts.reduce((acc,current) => acc + current.productQty * current.productPrice,0).toFixed(2);
    const TotalWithShip = (parseFloat(Total) + parseFloat( (shippingCharges ?? defaultNumber))).toFixed(2);
    
    const orderItem = cartProducts.map(item => (
        <li className="review-summary-item" key={`reviewOrder-${item.productID}`}>
            {`${item.productQty} x ${item.productBrand} ${item.productTitle}`}
            <span className="review-summary-price">{`R${item.productPrice}`}</span>
        </li>
    ))
    const paySelect = () => {
        if(formState.paymentMethod === "Payfast"){
            return <Payfast className="payment-image"/>
        }
        if(formState.paymentMethod === "Paypal"){
            return <Paypal className="payment-image"/>
        } 
    }
    return (
        <div className="review">
            <h1 className="review-heading">Review Your Order</h1> 
            <ul className="review-summary">
                {
                    orderItem
                }
                <div className="review-summary-shipping">{`Shipping`}
                    <span className="review-summary-price">{`R${shippingCharges ?? defaultNumber}`}</span>
                </div> 
                <div className="review-summary-total">{`Total`}
                    <span className="review-summary-price">{`R${TotalWithShip ?? defaultNumber}`}</span>
                </div> 
            </ul>
            

             <div className="review-container">
             <div className="review-billing">
                    <div className="review-header">
                        <div className="review-point">

                        </div>
                        <h1 className="review-heading">Shipping Information</h1>
                    </div>
                    <ul className="review-information">
                    <li className="review-field">{`${formState.shipName} ${formState.shipSurname}`}</li>
                        <li className="review-field">{`${formState.shipEmail}`}</li>
                        <li className="review-field">{`${formState.shipAddress}`}</li>
                        <li className="review-field">{`${formState.shipCity}, ${formState.shipCountry}`}</li>
                        <li className="review-field">{`T: ${formState.shipMobile}`}</li>
                        <li classname="review-field">{}</li>
                        <li className="review-field">{`${formState.deliveryInfo}`}</li>
                    </ul>
                </div>
                <div className="review-billing">
                    <div className="review-header">
                        <div className="review-point">

                        </div>
                        <h1 className="review-heading">Billing Information</h1>
                    </div>
                    <ul className="review-information">
                        <li className="review-field">{`${formState.billName} ${formState.billSurname}`}</li>
                        <li className="review-field">{`${formState.billEmail}`}</li>
                        <li className="review-field">{`${formState.billAddress}`}</li>
                        <li className="review-field">{`T: ${formState.billMobile}`}</li>
                    </ul>
                </div>
            </div>
            <div className="review-payment">
                    <h2 className="payment-heading">Payment Method :</h2>
                    <div className="payment-method">
                        <div className="payment-select">
                            {
                                paySelect()
                            }
                            <p className="payment-notice">
                                You will be redirected to the VCS website when you place an order.
                            </p>
                        </div>
                        
                       
                        <div className={`payment-order  ${paymentMethod === "Paypal" ? "back-paypal":""}`}>
                            <button className={`form-back`} name="back" onClick={() => history.push("/checkout/payment")}><span className="form-back-text">&#8592;</span>back</button>
                            {
                                cartProducts.length > 0 ? (paymentMethod !== "Paypal" ? 
                                    <PayWithPayFast formState={formState} cartProducts={cartProducts} shippingCharges={shippingCharges} TotalWithShip={TotalWithShip}/> : 
                                    <PayWithPaypal formState={formState} cartProducts={cartProducts} shippingCharges={shippingCharges} Total={Total} TotalWithShip={TotalWithShip}/> 
                                ) :
                                (
                                    <button  className="form-next" onClick={() => history.push('/category/Food')}>
                                            Shop
                                    </button>
                                )
                            }
                        </div>
                    </div>
            </div>
            
        </div>
    )
}

export default Review
