import React,{createContext,useReducer} from 'react'

export const PromotionsContext = createContext();
//Define initial State
const initialState = {
    promoType : ""
}
//Define Reducer
const reducer = (state, action) => {
    switch(action.type){
        
        default :
            return state
    }
}

const PromotionsContextProvider = (props) => {
    const [promoState, promoDispatch] = useReducer(reducer, initialState )
    return (
        <PromotionsContext.Provider value={{...promoState, promoDispatch}}>
            {props.children}
        </PromotionsContext.Provider>
        
    )
}

export default PromotionsContextProvider
